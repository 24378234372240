<!--
 * @Descripttion:
 * @version:
 * @Author: guomengxia
 * @Date: 2023-11-19 13:36:07
 * @LastEditors: guomengxia
 * @LastEditTime: 2024-03-25 10:29:29
-->
<template>
  <div>
    <template>
      <div class="second-level">
        <div class="return-tit">
          <div class="f14 cu-p" @click="_backBtn"><i class="el-icon-back mr10"></i>返回</div>
          <span class="division">|</span>
          <div class="f16 col333">题库管理</div>
        </div>
        <el-form class="form-item-no-mb" ref="searchForm" inline :model="searchForm" size="small">
          <el-form-item label="题目名称">
            <el-input v-model="searchForm.title" placeholder="请输入题目名称" clearable></el-input>
          </el-form-item>
          <el-form-item label="题目类型">
            <el-select v-model="searchForm.questionType" filterable clearable>
              <el-option v-for="item in questionTypeList" :key="item.key" :value="item.key" :label="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item style="margin-left:22px;">
            <el-button @click="_resetBtn()">重置</el-button>
            <el-button type="primary" style="margin-left:16px;" @click="dataInit()">查询</el-button>
          </el-form-item>
        </el-form>
        <el-button type="primary" @click="_addTopicHandle"><i class="el-icon-plus"></i><span>新增题目</span></el-button>
        <el-button style="margin-left:16px;" @click="_importBtn">导入题目</el-button>
        <el-button type="danger" style="margin-left:16px;" @click="_deleteManyTopic">批量删除</el-button>
      </div>
      <div class="index-list-table mt16">
        <el-table
          ref="multipleTable"
          :data="tableData"
          height="calc(100vh - 350px)"
          :header-cell-style="cellHeaderStyleTwo"
          :cell-style="cellStyleTwo"
          @selection-change="_selectTableData"
          :row-key="_getRowKeys">
          <el-table-column type="selection" width="55" :reserve-selection="true"></el-table-column>
          <el-table-column prop="title" label="题目名称">
            <template slot-scope="scope">{{scope.row.title||'—'}}</template>
          </el-table-column>
          <el-table-column prop="questionType" label="题目类型">
            <template slot-scope="scope">{{_getQuestionTypeName(scope.row.questionType)}}</template>
          </el-table-column>
          <el-table-column prop="score" label="分数">
            <template slot-scope="scope">{{scope.row.score||'—'}}</template>
          </el-table-column>
          <el-table-column prop="createName" label="创建人">
            <template slot-scope="scope">{{scope.row.createName||'—'}}</template>
          </el-table-column>
          <el-table-column prop="createTime" label="创建时间">
            <template slot-scope="scope">{{scope.row.createTime||'—'}}</template>
          </el-table-column>
          <el-table-column label="操作" width="120px">
            <template slot-scope="scope">
              <el-button type="text" @click="_editTopic(scope.row)" class="text-btn mr24">编辑</el-button>
              <el-button type="text" class="text-btn" @click="_deleteQuestionTitleBtn([scope.row.id])">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="fx-row-end pt16 pagination-style"
          @size-change="_handleSizeChange"
          @current-change="_handleCurrentChange"
          :current-page="searchForm.page"
          :page-sizes="[10, 20, 30, 40, 50]"
          :page-size="searchForm.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="tableTotal">
        </el-pagination>
      </div>
    </template>
    <!-- 导入区域开始 -->
    <uploadIm-dialog
      v-if="dialogVisible"
      :dialogTitle="dialogTitle"
      :visible.sync="dialogVisible"
      :downLoadTitle="downLoadTitle"
      :rules="dialogRules"
      @updateVisible="_updateVisible"
      @resetPopupData="_resetPopupData"
      @submitPopupData="_submitPopupData"
      :showProcess="showProcess"
      :processData="processData"
      @handleClose="_handleClose"
      @done="_uploadDone"
      @uploadChange="_uploadChange"
      :popupWidth="'651px'"
      :errorContent="errorContent"
    />
    <!-- 导入区域结束 -->
  </div>
</template>
<script>
import uploadImDialog from '@/components/uploadImDialog/index.vue'
import { topicManagePageListApi, topicManageDeleteApi } from '@/api/examApi'
import tableFirstCol from '@/mixins/tableFirstCol'
import deleteFinalData from '@/mixins/deleteFinalData'
import { getCookie } from '@/utils/util'
export default {
  components: { uploadImDialog },
  mixins: [tableFirstCol, deleteFinalData],
  data () {
    return {
      questionTypeList: [
        { key: 'SINGLE_CHOICE_TYPE', value: '单项选择' },
        { key: 'MULTIPLE_CHOICE_TYPE', value: '多项选择' },
        { key: 'JUDGMENT_TYPE', value: '判断题' },
        { key: 'GAP_FILLING_TYPE', value: '填空题' },
        { key: 'ESSAY_QUESTION_TYPE', value: '问答题' }
      ],
      searchForm: {
        questionBankId: '', // 题库id
        questionType: '', // 题目类型
        title: '', // 题目
        page: 1,
        pageSize: 10
      },
      tableData: [],
      tableTotal: 0,
      addTopicShow: false,
      dialogTitle: '导入题目', // 标题
      dialogVisible: false, // 弹框的出现与否
      downLoadTitle: '题目管理', //
      dialogRules: false, // 弹窗查重规则是否展示
      processData: 0,
      showProcess: false,
      errorContent: [], // 错误信息
      selectedQuestionKeys: []
    }
  },
  created () {
    this.searchForm.questionBankId = this.$route.query.questionBankId
    this._getQuestionTitleList()
  },
  watch: {
    'searchForm.page' (newValue, oldValue) {
      console.log(newValue, oldValue, 'kkkk1111')
    }
  },
  methods: {
    init () {
    },
    // 题目列表
    _getQuestionTitleList () {
      topicManagePageListApi({ ...this.searchForm }).then((res) => {
        if (res.code === 200) {
          this.tableData = res.object.results
          this.tableTotal = res.object.total
        }
      })
    },
    // 获取题目类型
    _getQuestionTypeName (type) {
      let value
      switch (type) {
        case 'SINGLE_CHOICE_TYPE':
          value = '单项选择'
          break
        case 'MULTIPLE_CHOICE_TYPE':
          value = '多项选择'
          break
        case 'JUDGMENT_TYPE':
          value = '判断题'
          break
        case 'GAP_FILLING_TYPE':
          value = '填空题'
          break
        case 'ESSAY_QUESTION_TYPE':
          value = '问答题'
          break
        default:
          value = '—'
          break
      }
      return value
    },
    // 删除题目类型
    _deleteQuestionTitleBtn (ids) {
      this.$confirm('确定要删除吗？', '提示', { type: 'warning' }).then(() => {
        topicManageDeleteApi(ids).then((res) => {
          if (res.code === 200) {
            this.$message.success('删除成功！')
            this.searchForm.page = this._deleteFinalData(this.tableTotal - ids.length, this.searchForm.pageSize, this.searchForm.page)
            this.$refs.multipleTable.clearSelection()
            this._getQuestionTitleList()
          }
        })
      }).catch(() => {})
    },
    // 查询
    dataInit () {
      this._getQuestionTitleList()
    },
    // 重置
    _resetBtn () {
      this.searchForm.questionType = ''
      this.searchForm.title = ''
      this.searchForm.page = 1
      this.searchForm.pageSize = 10
      this._getQuestionTitleList()
    },
    _handleSizeChange (val) {
      this.searchForm.pageSize = val
      this._getQuestionTitleList()
    },
    _handleCurrentChange (val) {
      this.searchForm.page = val
      this._getQuestionTitleList()
    },
    // 新增题目
    _addTopicHandle () {
      this.$router.push({
        path: '/addTopic',
        query: {
          questionBankId: this.$route.query.questionBankId
        }
      })
    },
    _backBtn () {
      this.$router.push({
        path: '/testBaseManage'
      })
    },
    _editTopic (row) {
      console.log(row, 'scope')
      this.$router.push({
        path: '/editTopic',
        query: {
          questionBankId: this.$route.query.questionBankId,
          editDataId: row.id
        }
      })
    },
    // 导入
    _importBtn () {
      this.errorContent = []
      this.dialogVisible = true
    },
    // 修改是否让页面显示与隐藏的事件
    _updateVisible (val) {
      this.dialogVisible = val
    },
    // 点击取消的事件
    _resetPopupData () {
      //  这里可重置数据
      this.dialogVisible = false
    },
    // 导入--- 点击确定的按钮
    async _submitPopupData (data) {
      this.showProcess = true
      this.processData = 0
      const formData = new FormData()
      formData.append('multipartFile', data)
      formData.append('questionBankId', this.$route.query.questionBankId)
      this.$axios
        .post(
          process.env.VUE_APP_BASE_API + 'train/questionInfo/questionImport',
          formData,
          {
            headers: { token: getCookie('token') }
          }
        ).then((res) => {
          this.processData = 50
          if (res.data.code === 200) {
            this.$message({
              type: 'success',
              message: '上传成功!'
            })
            this.dialogVisible = false
            this._getQuestionTitleList() // 查询列表
          } else {
            // this.dialogVisible = false
            this.showProcess = false
            if (res.data.message) {
              this.errorContent.push(res.data.message)
            }
            if (res.data.data) {
              this.errorContent.push(res.data.data)
            }

            // console.log(res, 'res.message')
            this.$message({
              type: 'error',
              message: '上传失败'
            })
          }
        }).finally(() => {
          this.processData = 100
          setTimeout(() => {
            this.showProcess = false
          }, 1000)
        })
    },
    // 关闭弹框（头部的X）
    _handleClose () {
      this.dialogVisible = false
    },
    // 导入成功
    _uploadDone () {
      // this.dialogVisible = false
    },
    // 改变上传
    _uploadChange () {
      this.errorContent = []
    },
    // 选择题目
    _selectTableData (val) {
      this.selectedQuestionKeys = val
    },
    _getRowKeys (row) {
      return row.id
    },
    // 批量删除题目
    _deleteManyTopic () {
      if (this.selectedQuestionKeys.length === 0) {
        return this.$message.error('请选择题目！')
      }
      const questionIds = this.selectedQuestionKeys.map((item) => {
        return item.id
      })
      this._deleteQuestionTitleBtn(questionIds)
    }
  }
}
</script>
<style lang="scss" scoped>
.pagination-style{
  background-color: #ffffff;
  padding-bottom: 10px;

}
</style>
