<!--
 * @Descripttion:导入组件
 * @version:
 * @Author: wenboliu
 * @Date: 2023-06-20 14:59:33
 * @LastEditors: wenwen
 * @LastEditTime: 2024-03-18 16:33:57
-->
<template>
  <!-- 封装弹框 -->
  <div class="popup">
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      :width="popupWidth"
      :before-close="_handleClose"
      :close-on-click-modal="false"
    >
      <div class="dialogContent">
        <el-row>
          <el-col :span="7">
            <div class="dialogContent-title">
              <img src="@/assets/img/icon_upload.png"/>
              <span>上传文件</span>
            </div>
          </el-col>
          <el-col :span="10">
            <div class="dialogContent-title">
              <i>></i>
              <img src="@/assets/img/icon_into.png" style="margin-left: 24px;"/>
              <span>导入数据</span>
              <i style="margin-left: 24px;">></i>
            </div>
          </el-col>
          <el-col :span="7">
            <div class="dialogContent-title">
              <img src="@/assets/img/icon_complete.png" style="margin-left: 20px;"/>
              <span>导入完成</span>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="dialogSubstance">
        <p class="substanceOne">
          一、请按照数据模板的格式准备要导入的数据。<span style="color: #0089FF;cursor: pointer;" :style="{'color':$store.state.settings.theme}" @click="downLoad(downLoadTitle)">点击下载《导入模板》</span><br/>
          <span style="font-size: 14px;color: #666;">导入文件请勿超过<span style="color: #0089FF;" :style="{'color':$store.state.settings.theme}">5MB</span>(约25000条数据)</span>
        </p>
        <p v-if="rules">
          二、请按照数据重复时的处理方式。(查重规则：【无】)<br/>
          <span style="color:  rgba(0,0,0,0.65);font-size: 14px;">查重规则为：根据联系方式去重</span><br/>
          <el-radio-group v-model="dupCheckRule">
            <el-radio :label="0">跳过</el-radio>
            <el-radio :label="1">覆盖系统原有数据</el-radio>
          </el-radio-group>
        </p>
        <p>
          <span>{{ rules ? '三' : '二'}}、</span>请选择需要导入的文件
          <el-upload
            :auto-upload="false"
            ref="upload"
            class="upload-demo"
            :headers="uploadHeader"
            :action="actions"
            :on-preview="_handlePreview"
            :on-remove="_handleRemove"
            :before-remove="_beforeRemove"
            :on-change="_changeUpload"
            :on-progress="progress"
            multiple
            :limit="1"
            :on-exceed="_handleExceed"
            :on-success="_handleSuccess"
            accept=".xlsx,.xls"
            :file-list="fileList">
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
          <span v-if="showProcess"><el-progress :percentage="processData"></el-progress></span>
        </p>
      </div>
      <slot v-if="errorContent.length > 0">
        <div style="padding: 0 32px;height: 100px;overflow-y: scroll;">
          <p class="err">
            <span v-for="(item, index) in errorContent" :key="index">
              {{ item }}
              <br />
            </span>
          </p>
        </div>
      </slot>
      <span slot="footer" class="dialog-footer">
        <el-button @click="_cancel">取 消</el-button>
        <el-button type="primary" @click="_importNow">立即导入</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getCookie } from '@/utils/util'
export default {
  props: {
    dialogTitle: {
      type: String,
      default: '标题'
    },
    visible: {
      type: Boolean,
      default: false
    },
    popupWidth: {
      type: String,
      default: '651px'
    },
    // 规则
    rules: {
      type: Boolean,
      default: true
    },
    dupCheckRule: {
      type: Number,
      default: 0
    },
    actions: {
      type: String,
      default: ''
    },
    errorContent: {
      type: Array,
      default: () => {
        return []
      }
    },
    downLoadTitle: {
      type: String,
      default: ''
    },
    processData: {
      type: Number,
      default: 0
    },
    showProcess: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      fileList: [],
      uploadHeader: {
        token: getCookie('token')
      }
      // showProcess: false
      // currentPeocess: 0
    }
  },
  computed: {
    dialogVisible: {
      get () {
        return this.visible
      },
      set (val) {
        // 当visible改变的时候，触发父组件的 updateVisible方法，在该方法中更改传入子组件的 centerDialogVisible的值
        this.$emit('updateVisible', val)
      }
    }
  },
  methods: {
    _cancel () {
      this.$emit('resetPopupData')
    },
    _importNow () {
      if (this.$refs.upload.uploadFiles.length === 0) {
        this.$message.warning('请上传文件!')
        return false
      }
      const file = this.$refs.upload.uploadFiles.pop().raw // 这里获取上传的文件对象
      const formData = new FormData()
      formData.append('file', file)
      console.log(file, 'formData')
      this.$emit('submitPopupData', file)
    },
    _handleClose () {
      this.$emit('handleClose')
    },
    _handleRemove (file, fileList) {
      console.log(file, fileList)
    },
    _handlePreview (file) {
      console.log(file)
    },
    _handleExceed (files, fileList) {
      this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`)
    },
    _beforeRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    _changeUpload (file, fileList) {
      var isLt2M = file.size / 1024 / 1024 < 5
      if (!isLt2M) {
        this.$message({
          type: 'warning',
          message: '附件大小超限，文件不能超过 5M'
        })
        var i = fileList.indexOf(file)
        fileList.splice(i, 1) // 自动删除不符合要求的文件，不让它出现在预览列表中
        return false
      }
      this.$emit('uploadChange')
    },
    _handleSuccess (response, file, fileList) {
      console.log(response, file, fileList)
      if (file.status === '"success"') {
        console.log('hhhhjkuiewu')
        this.$emit('done')
      }
    },

    downLoad (downloadTitle) {
      console.log(downloadTitle, 'downloadTitle')
      if (downloadTitle === '题目管理') {
        window.open(
          'https://generate.yingshevip.com/upload/files/%E8%AF%95%E9%A2%98%E5%AF%BC%E5%85%A5%E7%A4%BA%E4%BE%8B%E6%96%87%E6%A1%A3.xlsx',
          '_blank'
        )
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.popup {
  .err{
    color: #FF4F4F;
  }
  /deep/.el-dialog {
    display: flex;
    z-index: 10000;
    background-image: url(../../assets/img/img_bg_into.png);
    background-size: 100%;
    background-repeat: no-repeat;
    border-radius: 5px;
    margin-top: 10% !important;
    width: 651px;
    // height: 472px;
    flex-direction: column;
    background-color: transparent;
  }
  /deep/.el-dialog__header {
    padding: 52px 32px 10px 32px!important;
  }
  .el-dialog__close.el-icon.el-icon-close {
    font-size: 20px;
    width: 17px;
    height: 17px;
  }
  .el-button.el-button--primary {
    width: 88px;
  }
  /deep/.el-dialog__body {
    flex: 1;
    padding: 0 32px;
    box-sizing: border-box;
  }
  /deep/.el-dialog__headerbtn {
    top: 52px;
    right:32px;
    font-size: 22px;
    color: #999;
  }
  /deep/.el-dialog__footer{
    background: #F7FCFF;
  }
  /deep/.el-dialog__title {
    font-size: 24px;
    font-weight: 500;
    color: rgba(0,0,0,0.9);
  }
  .dialogContent{
    padding: 24px 60px 6px 60px;
    .dialogContent-title{
      display: flex;
      align-items: center;
      img{
        width: 36px;
        height: 36px;
      }
      span{
        margin-left: 6px;
        font-size: 18px;
        font-weight: 400;
        color: rgba(0,0,0,0.9);
      }
      i {
        display: flex;
        color: #0089FF;
        font-size: 15px;
        font-weight: 500;
      }
    }
  }
  .dialogSubstance{
    padding: 16px 32px 0 32px;
    p{
      margin-bottom: 24px;
      color: rgba(0,0,0,0.9);
      font-size: 14px;
    }
    .substanceOne{
      font-size: 16px;
      font-weight: 400;
      color: rgba(0,0,0,0.9);
    }
    p:last-child{
      margin-bottom: 10px;
    }
  }
  /deep/.el-upload--text{
    width: auto;
    height: auto;
    border: 0;
  }
  .upload-demo{
    margin-top: 10px;
  }
  .el-radio-group{
    margin-top: 14px;
  }
}
</style>
