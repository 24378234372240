/*
 * @Descripttion:
 * @version:
 * @Author: guomengxia
 * @Date: 2024-02-26 17:48:05
 * @LastEditors: guomengxia
 * @LastEditTime: 2024-03-14 15:06:11
 */
export default {
  data () {
    return {
    }
  },
  methods: {
    // 只针对单个删除---删除最后一条数据
    _deleteFinalData (deleteAfterTotal, currentPageSize, currentPageParam) {
      const deleteAfterPage = Math.ceil((deleteAfterTotal) / currentPageSize)
      const currentPage = currentPageParam > deleteAfterPage ? deleteAfterPage : currentPageParam
      const page = currentPage < 1 ? 1 : currentPage
      return page
    }
  }
}
